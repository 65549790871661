import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  onMainEvent: EventEmitter = new EventEmitter();
  constructor() { }
}
