// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: false, 
// Api_url: "https://socketwebrtc.rcubinity.com:3010", 
//  local_url: "http://localhost:4200",
  // local_url: "https://classroam.in/edterra-frontend/",
  Api_url: "https://api.class-roam.in", 
  local_url: "https://exploratorium.class-roam.in/", 
      // local_url: "https://classroam.in/", 
      // Api_url: "https://edt.classroam.in",
  
 // local_url: "http://localhost:4200",
  //  Api_url: "https://edt.classroam.in",
  // Api_url: "http://67.209.127.45:3010",
  // Api_url: "http://192.168.1.45:3000" 
  firebaseConfig: {
    apiKey: "AIzaSyA-SmX_VG7hRA20_1R7QSgIqGYuLrKCO60",
    authDomain: "edterra-4e9a5.firebaseapp.com",
    databaseURL: "https://edterra-4e9a5.firebaseio.com",
    projectId: "edterra-4e9a5",
    storageBucket: "edterra-4e9a5.appspot.com",
    messagingSenderId: "223696408283",
    appId: "1:223696408283:web:919f85700ebac9af1886fe",
    measurementId: "G-T9JWSYGM9S"
  }
};
