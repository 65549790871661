import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AdminService {
  token = window.localStorage.getItem('token')

  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    })
  };
  httpOptions4 = {
    headers: new HttpHeaders({

    })
  };



  API_URL = environment.Api_url;
  constructor(private httpClient: HttpClient) { }


  addfeedback(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/admin/addfeedback', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    });
}

checkDataBystudentId(data) {
  let token2 = window.localStorage.getItem('token')
  return this.httpClient.post(this.API_URL + '/admin/checkDataBystudentId', data, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token2
    })
  });
}
  UserLogin(data) {
    return this.httpClient.post(this.API_URL + '/auth/user/login', data, this.httpOptions2)
  }
  logout(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/auth/user/logout', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  hit(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/auth/user/hit', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  studentCertificate1(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/studentCertificate1', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  SendOtp(data) {
    return this.httpClient.post(this.API_URL + '/auth/user/sendotp', data, this.httpOptions2)
  }
  SendOtp2(data) {
    return this.httpClient.post(this.API_URL + '/auth/user/sendotp2', data, this.httpOptions2)
  }
  VerifyOtp(data) {
    return this.httpClient.post(this.API_URL + '/auth/user/verifyotp', data, this.httpOptions2)
  }
  UserDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/details', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UpdateUserDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/details/edit', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  addProfileByJourney(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/addProfileByJourney', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherLogin(data) {
    return this.httpClient.post(this.API_URL + '/auth/teacher/login', data, this.httpOptions2)
  }
  TeacherSendOtp(data) {
    return this.httpClient.post(this.API_URL + '/auth/teacher/sendotp', data, this.httpOptions2)
  }
  TeacherVerifyOtp(data) {
    return this.httpClient.post(this.API_URL + '/auth/teacher/verifyotp', data, this.httpOptions2)
  }
  TeacherDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/details', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  MentorDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/jm/details', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  Notification(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/notification', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UpdateTeacherDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/details/edit', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JmLogin(data) {
    return this.httpClient.post(this.API_URL + '/auth/jm/login', data, this.httpOptions2)
  }
  JmSendOtp(data) {
    return this.httpClient.post(this.API_URL + '/auth/jm/sendotp', data, this.httpOptions2)
  }
  JmVerifyOtp(data) {
    return this.httpClient.post(this.API_URL + '/auth/jm/verifyotp', data, this.httpOptions2)
  }
  JmDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/jm/details', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UpdateJmDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/jm/details/edit', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserMediaProject(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/media-project', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherMediaProject(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/media-project', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  DescoverySheetsQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/discoverysheetquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  SkillFactoryQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/skillfactoryquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  DescoverySheetsResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/discoverysheetresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  ImproveScoreResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/improvescoreresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  setting(data) {
      let token2 = window.localStorage.getItem('token')
      return this.httpClient.post(this.API_URL + '/user/student/setting', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token2
        })
      });
  }


  updatesetting(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/updatesetting', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    });
}



  ImproveScoreResultTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/improvescoreresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  ImproveScoreResultJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/improvescoreresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  SkillFactoryResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/skillfacktoryresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  SkillFactoryResultDone(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/skillfacktoryresultDone', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  SkillFactoryResult2(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/skillfacktoryresult2', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherDescoverySheetsQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/discoverysheetquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSkillFactoryQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/skillfactoryquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMDescoverySheetsQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/discoverysheetquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMSkillFactoryQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/skillfactoryquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  TeacherDescoverySheetsResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/discoverysheetresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSkillFactoryResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/skillfacktoryresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }



  JMDescoverySheetsResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/discoverysheetresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMSkillFactoryResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/skillfacktoryresult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }



  UserDescoverySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journey/descoverysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  UserFaq(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/usingfaq', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherDescoverySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journey/descoverysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMDescoverySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journey/descoverysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserSkillFactory(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journey/getskillfactory', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSkillFactory(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journey/getskillfactory', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMSkillFactory(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journey/getskillfactory', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserSubjectData(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journey/skillfactory/subjectdata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  
  TecherSubjectData(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journey/skillfactory/subjectdata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMSubjectData(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journey/skillfactory/subjectdata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  playDescoverySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journey/playdescoverysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherplayDescoverySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journey/playdescoverysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMplayDescoverySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journey/playdescoverysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  playSkillFactorySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journey/playskillfactorysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherPlaySkillFactorySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journey/playskillfactorysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMPlaySkillFactorySheets(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journey/playskillfactorysheets', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getTT(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/gettt', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getStatusTT(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getttStatus', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getJMTT(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/gettt', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getTTtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/gettttext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getTTIntro(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getttintro', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveTTtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/savetttext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveTTaudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/savettaudio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  ttForReview(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/ttforreview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getReviewDataByStudent(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getReviewDataByStudent', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getReviewDataByTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getReviewDataByStudent', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  MyGroup(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/mygroup', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  MyGroup2(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/mygroupstudentdata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  MyGroupJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/mygroup', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  MyGroup2JM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/mygroupstudentdata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserSummary(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/usersummary', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  addVideoPoints(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/addVideoPoints', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  addVideoPointsTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/addVideoPointsTeacher', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  addVideoPointsJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/addVideoPointsTeacher', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  TeacherGetTT(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/gettt', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherGetTTtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/gettttext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherGetTTIntro(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getttintro', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveTTtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/savetttext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveTTaudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/savettaudio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherttForReview(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/ttforreview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  
  getYAP(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getyap', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getJMYAP(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getyap', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getYAPtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getyaptext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getYAPIntro(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getyapintro', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveYAPtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/saveyaptext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveYAPaudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/saveyapaudio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  YAPForReview(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/yapforreview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  
  TeacherGetYAP(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getyap', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherGetYAPtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getyaptext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherGetYAPIntro(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getyapintro', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveYAPtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/saveyaptext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveYAPaudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/saveyapaudio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherYAPForReview(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/yapforreview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getBB(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getbb', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getJMBB(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getbb', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getBBtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getbbtext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getBBIntro(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getbbintro', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveBBtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/savebbtext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveBBaudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/savebbaudio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  BBForReview(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/bbforreview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getPercenatageForMediaProjects(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getPercenatageForMediaProjects', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getImprovestatus(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/improvestatus', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  getPercenatageForDiscoverySheet(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getPercenatageForDiscoverySheet', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getPercenatageForSkillFactorySheet(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getPercenatageForSkillFactory', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  


  getPercenatageForMediaProjectsTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getPercenatageForMediaProjects', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getImprovestatusTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/improvestatus', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  getPercenatageForDiscoverySheetTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getPercenatageForDiscoverySheet', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getPercenatageForSkillFactorySheetTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getPercenatageForSkillFactory', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }



  getPercenatageForMediaProjectsJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getPercenatageForMediaProjects', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getImprovestatusJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/improvestatus', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  getPercenatageForDiscoverySheetJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getPercenatageForDiscoverySheet', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getPercenatageForSkillFactorySheetJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getPercenatageForSkillFactory', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  

  TeacherGetBB(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getbb', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherGetBBtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getbbtext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherGetBBIntro(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getbbintro', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveBBtext(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/savebbtext', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveBBaudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/savebbaudio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherBBForReview(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/bbforreview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  KnowledgeSummary(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/knowledgesummary', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherKnowledgeSummary(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/knowledgesummary', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMKnowledgeSummary(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/knowledgesummary', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  categoryGet() {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.get(this.API_URL + '/admin/getcategory', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token2 }) })
  }
  categoryHomeGet() {
    return this.httpClient.get(this.API_URL + '/home/getcategory', { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  journeyGetBySlug(data) {
    // let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/journeybyslug', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }



  update_details(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/auth/update_details', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserJourneyReadinessDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journeyReadiness', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserImprove(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/improvescore', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserImproveTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teahcer/improvescore', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  UserJourneyReadinessResult(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journeyReadinessResult', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserJourneyReadinessQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/journeyReadinessquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherJourneyReadinessQuestion(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journeyReadinessquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserJourneyReadiness(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/dajourneyReadiness', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherUserJourneyReadiness(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/dajourneyReadiness', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMUserJourneyReadiness(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/dajourneyReadiness', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserHomeDetails(data) {
  let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/home', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserHomeDetails2(data) {
    let token2 = window.localStorage.getItem('token')
      return this.httpClient.post(this.API_URL + '/user/student/stdhome', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token2
        })
      })
  }
  TeacherHomeDetails(data) {
    let token2 = window.localStorage.getItem('token')
      return this.httpClient.post(this.API_URL + '/user/teacher/home', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMHomeDetails(data) {
    let token2 = window.localStorage.getItem('token')
      return this.httpClient.post(this.API_URL + '/user/mentor/home', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  UserFeedbackFormQuestion(data) {
  let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/userfeedbackformquestion', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/questionresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  teacherSaveResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/questionresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JmSaveResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/questionresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  instaapi(url) {
    return this.httpClient.get(url);
  }
  MentorSaveResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/questionresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  savefeedbackResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/questionfeedbackresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveDiscoveryResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/questiondiscoveryresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveImproveScore(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/improvescoreresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveImproveScoreTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/improvescoreresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveImproveScoreJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/improvescoreresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  saveSkillResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/questionskillfactoryresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSavefeedbackResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/questionfeedbackresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  JMSavefeedbackResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/questionfeedbackresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  TeacherSaveDiscoveryResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/questiondiscoveryresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMSaveDiscoveryResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/questiondiscoveryresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherSaveSkillResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/questionskillfactoryresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  JMSaveSkillResponse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/questionskillfactoryresponse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  TeacherJourneyReadinessDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/journeyReadiness', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  MentorJourneyReadinessDetails(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journeyReadiness', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  MentorJourneyReadinessDetails2(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/journeyReadiness2', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  StudentVideo(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/video', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  StudentImage(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/image', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  StudentAudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/audio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  StudentText(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/text', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  TeacherVideo(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/video', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherImage(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/image', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherAudio(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/audio', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  TeacherText(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/text', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  StudentTuckShop(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/tuckshop', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  StudentTuckShopTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/tuckshop', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  StudentTuckShopJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/tuckshop', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  badgeList(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/badgeslist', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  badgeListTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/badgeslist', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  badgeListMentor(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/badgeslist', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  addToWishlist(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/addtowishlist', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  
  addToWishlistTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/addtowishlist', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  addToWishlistMentor(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/addtowishlist', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  buyBadge(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/buybadge', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  buyBadgeTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/buybadge', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  buyBadgeMentor(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/buybadge', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  buyCourse(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/buycourse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  buyCourseTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/buycourse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  buyCourseJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/buycourse', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  dailyReportCard(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/dailyreportcard', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getReportCardpdfUrl(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getreportcardpdf', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  dailyReportCardall(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/dailyreportcardall', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  dailyReportCardTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/dailyreportcard', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  dailyReportCardallTeacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/dailyreportcardall', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  dailyReportCardJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/dailyreportcard', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  dailyReportCardallJM(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/dailyreportcardall', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  addcert(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/addstudentcert', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getcert(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/student/getstudentcert', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  imageUpload(data) {
    return this.httpClient.post(this.API_URL + '/file/image', data, this.httpOptions4)
  }
  imageUploadWithProgress(data) {
    return this.httpClient.post(this.API_URL + '/file/image', data, {
      reportProgress: true,
      observe: 'events'
    })
  }
  certUpload(data) {
    return this.httpClient.post(this.API_URL + '/file/cert', data, this.httpOptions4)
  }
  videoUpload(data) {
    return this.httpClient.post(this.API_URL + '/file/video', data, this.httpOptions4)
  }
  audioUpload(data) {
    return this.httpClient.post(this.API_URL + '/file/audio', data, this.httpOptions4)
  }
  audioUploadWithProgress(data) {
    return this.httpClient.post(this.API_URL + '/file/audio', data, {
      reportProgress: true,
      observe: 'events'
    })
  }


  getmentorJourneys(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getjourneys', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  setmentorJourneys(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/setjourney', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getgroupmember(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getgroupdata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getjourneyreportcard(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getjourneyreport', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }


  getmediaproject(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getmediadata', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getmediaproject2(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getmediadataforReview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getmediaproject2Teacher(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/getmediadataforReview', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  addgroup(data) {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/addgroup', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getgroup(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getgroup', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getgroupdetail(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getgroupdetails', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  getgroupdetailbytype(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getgroupdetailsbytype', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  accept(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/accept', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  acceptTeacher(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/accept', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

  reject(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/reject', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  rejectTeacher(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/teacher/reject', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  sendNotification(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/msg', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }
  getdetails(data)
  {
    let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/user/mentor/getdetails', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token2
      })
    })
  }

}