import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplashComponent } from './splash/splash.component';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'login-option/:login-option',
    loadChildren: () => import('./login-option/login-option.module').then(mod => mod.LoginOptionModule)
  },  
  {
    path: 'signup',
    loadChildren: () => import('./login/signup/signup.module').then(mod => mod.SignupModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'student',
    loadChildren: () => import('./student/student.module').then(mod => mod.StudentModule)
  },
  {
    path: ':userType/onboard',
    loadChildren: () => import('./common/onboarding/onboarding.module').then(mod => mod.OnboardingModule)
  },
  {
    path: 'login-option/:userType/onboard',
    loadChildren: () => import('./common/onboarding/onboarding.module').then(mod => mod.OnboardingModule)
  },
  {
    path: 'teacher',
    loadChildren: () => import('./teacher/student.module').then(mod => mod.StudentModule)
  }, 
  {
    path: 'journey-mentor',
    loadChildren: () => import('./journey-mentor/journey-mentor.module').then(mod => mod.StudentModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(mod => mod.HelpModule)
  },
  {
    path:'',
    component:SplashComponent
  },
  { 
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
