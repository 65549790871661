import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { AdminService } from './services/admin.service';
import { SharedService } from './student/dashboard/shared.service';
import { environment } from 'src/environments/environment';
declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'Edterra user';
  loading;
  extra;
  loading2 = false;
  constructor(
    private router: Router,
    private _api:AdminService,
    private ser:SharedService
  ) {
    if(window.innerWidth>=768)
    {
      this.loading = true;
    }
    else{
   this.loading = false;
   this.extra = false;
    }
  }

  clicked=true;
  sound;
  soundfunc(src) {
if(window.localStorage.getItem("soundplay")=="false"){
      return;
}
    
    this.sound = document.createElement("audio");
    this.sound.src = environment.local_url+"/assets/sounds/notification/"+src;
    this.sound.setAttribute("preload", "auto");
    this.sound.setAttribute("controls", "none");
    this.sound.style.display = "none";
    document.body.appendChild(this.sound);
    //this.play = function(){
      this.sound.play();
    //}
    //this.stop = function(){
     // this.sound.pause();
    //}
  }





  ngOnInit(){
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: 'For a seamless experience Classroam stores cookies on your system. Please enable cookies for classroom.',
        dismiss: 'Okay',
        link: '',
        // href: environment.Frontend + "/dataprivacy" 
      }
    });
    // window.addEventListener('popstate',(event)=> {
    //   console.log(event);
    //   alert("inside"+window.location.href);
    //   if(window.location.href.includes("sheet-detials"))
    //   {
    //     alert("inside");
    //     this.router.navigate(['/student/dashboard']);
    //     event.preventDefault();
    //   }
      
    //   this.router.navigate(['/student/dashboard']);
    //   event.preventDefault();
    //  //  alert("back");
    // });
    document.addEventListener('click',(e)=>{
      //alert("click");
      // console.log(e);
      if(e.target['localName']=="a" || e.target['localName']=="button" || e.target['classList'].contains("soundev") || e.target['classList'].contains("mat-checkbox-inner-container")){
        // console.log(e);
        this.soundfunc("Click.mp3");
      }
    });

    window.onload=()=>{
      setTimeout(() => {
        this.loading = true;
        this.extra = false;
      
     //   this.router.navigate(['/loginhome']);
      }, 3000);
      var item= window.localStorage.getItem('user_id');
      if(item){
            window.clearInterval(window['intr']);
            var intr = setInterval(()=>{
              this._api.hit({}).subscribe((res:any)=>{
                  console.log(res);
                    //this.ser.onMainEvent.emit("changed",[res.notification,res.notificationCount]);
                    this.emitevent(res.notification,res.notificationCount);
                    console.log("emit event");
              },(err)=>{
                      console.log(err);
              });
            },5000);
            window['intr'] = intr;
      }
    }

    if(window.innerWidth>=768)
    {
      if(window.location.pathname==''){
        this.router.navigate(['/home']);
      }
    }
  }
  emitevent(n,n2)
  {
    this.ser.onMainEvent.emit("changed",[n,n2]);
  }
  ngAfterViewInit() { 
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          // if(!this.loading){
          //   this.extra = true;
          // }
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          // setTimeout(() => {
          //   this.loading2 = true;
          // }, 1000)
        }
      });
   
    
  }
  ngAfterViewChecked(){
    this.loading2 = false;
  }
  lodingTrue(){
   this.extra = true;
   this.loading2=true;
   this.loading = true;
  
   setTimeout(() => {
   // $(".backdrop").css("height",$(".router-output").height()+"px");
   
   }, 10);
   //$(".backdrop").css("height",$(".router-output").height()+"px");
   //this.loading=true;
  }
  lodingFalse(){
    this.extra = false;

   this.loading2=false;

   this.loading = true;
    //this.loading=false;
  }
  onDeactivate() {
    // document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    window.scrollTo(0, 0)
  }


  basesvg=``


}
