import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebcamModule } from 'ngx-webcam';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PopupComponent } from './student/dashboard/media-projects/audiofile/popup/popup.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'; 
import { EmbedVideo } from 'ngx-embed-video';

import { SocialLoginModule } from 'angularx-social-login';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider } from 'angularx-social-login';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafePipePipe } from './student/dashboard/safe-pipe.pipe';
import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatCheckboxModule } from '@angular/material';
import { SplashComponent } from './splash/splash.component';
// BJKhGriD4o9ctUOvjrxB8WSp    ---client secret
// 772259399645-oeg5f2bvhsiouc9grdvel9drabdvg5cn.apps.googleusercontent.com    --client id
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    //provider: new GoogleLoginProvider('1043032547186-0tmv5kn233qjm4cqmr28cso2m6d8bcfq.apps.googleusercontent.com')
    // provider: new GoogleLoginProvider('824110137129-lh2u67c477f8unmkrekm5ds1rtske6go.apps.googleusercontent.com')
    provider: new GoogleLoginProvider('772259399645-oeg5f2bvhsiouc9grdvel9drabdvg5cn.apps.googleusercontent.com')

  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    // provider: new FacebookLoginProvider('613827689152731')
    provider: new FacebookLoginProvider('399637184644700')
  },
  {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider("811ljthqvd6xw8")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
    SplashComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    WebcamModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SocialLoginModule,
    LyThemeModule.setTheme('minima-light'),
    EmbedVideo.forRoot(),
    NgxLoadingModule.forRoot({
      // backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      // backdropBorderRadius: '4px',
      // primaryColour: '#ffffff',
      // secondaryColour: '#ffffff',
      // tertiaryColour: '#ffffff'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, 
    
  ],
  
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true } // name: `minima-dark`
  
  ],
  bootstrap: [AppComponent],
  entryComponents:[PopupComponent],

})
export class AppModule { }
